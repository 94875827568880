<template>
  <div class="notfound-page">
    <h1>
      <span>404</span>
      <br>
      Page Not Found
    </h1>
    <!--    <h2>アクセスしようとしたページは見つかりませんでした。</h2>-->
    <!--    <p class="explain">-->
    <!--      このエラーは、指定したページが見つからなかったことを意味します。-->
    <!--    </p>-->
    <!--    <h3>以下のような原因が考えられます。</h3>-->
    <!--    <div class="white-box">-->
    <!--      <div class="cause">-->
    <!--        <ul>-->
    <!--          <li>アクセスしようとしたファイルが存在しない（ファイルの設置箇所を誤っている）。</li>-->
    <!--          <li>URLアドレスが間違っている。</li>-->
    <!--        </ul>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.notfound-page {
  background-color: #80C4CC;
  color: #fff;
  font-family: "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
  font-size: 14px;
  min-height: 100vh;
  padding: 30px 10px;
  text-align: center;
  overflow: auto;
  max-height: 100vh;

  h1 {
    font-size: 26px;
    font-weight: bold;
    padding-bottom: 20px;
    width: 100%;

    span {
      font-size: 60px;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .explain {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    font-size: 14px;
    line-height: 1.5;
    margin: 30px auto;
    padding: 17px;
  }

  .white-box {
    background-color: white;
    margin: 15px auto 0;

    .cause {
      font-size: 12px;
      text-align: left;
      padding: 20px;

      ul {
        list-style-type: disc;
        padding-left: 2em;

        li {
          color: #666;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .notfound-page {
    padding: 0;
    padding-top: 80px;

    h1 {
      font-size: 50px;

      span {
        font-size: 110px;
      }
    }

    h2 {
      font-size: 27px;
    }

    .explain {
      font-size: 16px;
      width: 660px;
    }

    h3 {
      font-size: 20px;
      margin-top: 80px;
    }

    .white-box {
      margin-bottom: 50px;
      width: 680px;

      .cause {
        font-size: 14px;
        padding: 30px;
        text-align: left;

        ul {
          list-style-type: disc;
          padding-left: 2em;
        }
      }
    }
  }
}
</style>
